.main[data-v-2128caa9] {
  display: flex;
}
.pic[data-v-2128caa9] {
  width: 290px;
  height: 140px;
  background-image: url(../img/order.0b21b56a.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  position: relative;
}
.order_amount[data-v-2128caa9] {
  padding: 35px 0 0 10px;
  color: #fff;
  display: flex;
  align-items: center;
}
.order_amount .order_amount_icon[data-v-2128caa9] {
  width: 20px;
  height: 20px;
  background-color: #fff;
  border-radius: 3px;
  margin-right: 5px;
  text-align: center;
  line-height: 20px;
}
.order_amount .order_amount_icon .iconfont[data-v-2128caa9] {
  color: #17a2b8;
  font-size: 18px;
}
.order_btn[data-v-2128caa9] {
  position: absolute;
  bottom: 10px;
  right: 30px;
}
.main_right[data-v-2128caa9] {
  width: calc(100% - 300px);
  margin-left: 20px;
}
.content tr[data-v-2128caa9] {
  padding: 0;
  margin: 0;
}
.content td[data-v-2128caa9] {
  border-bottom: 1px solid #efefef;
  font-size: 13px;
  line-height: 35px;
}
.center[data-v-2128caa9] {
  text-align: center;
}
.fcolor[data-v-2128caa9] {
  color: #17a2b8;
}
.weight[data-v-2128caa9] {
  font-weight: 600;
}
.list[data-v-2128caa9] {
  padding: 20px;
  background-color: #f8f8f8;
}
.list_top[data-v-2128caa9] {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.list_top .list_icon[data-v-2128caa9] {
  width: 20px;
  height: 20px;
  background-color: #17a2b8;
  border-radius: 3px;
  margin-right: 5px;
  text-align: center;
}
.top_color[data-v-2128caa9] {
  background-color: #ecf7f9;
}
.content_list[data-v-2128caa9] {
  width: 100%;
  border: 1px solid #e5ecf0;
  border-bottom: none;
  border-right: none;
}
.content_list tr[data-v-2128caa9] {
  padding: 0;
  margin: 0;
}
.content_list td[data-v-2128caa9] {
  border-right: 1px solid #e5ecf0;
  border-bottom: 1px solid #e5ecf0;
  line-height: 35px;
  padding: 0 6px;
  color: #333;
  font-size: 13px;
}
.text_left[data-v-2128caa9] {
  width: 95%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}