.slider-demo-block[data-v-fde20090] {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 30px;
}
.slider-demo-block .el-slider[data-v-fde20090] {
  width: 82%;
  margin-top: 0;
  margin-left: 12px;
}
.pay[data-v-fde20090] {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.1);
  z-index: 999;
}
.pay .pay_main[data-v-fde20090] {
  position: fixed;
  left: 50%;
  transform: translate(-50%, -12%);
  top: 25%;
  z-index: 999;
  width: 315px;
  background-color: #fff;
  border-radius: 16px;
  text-align: center;
  padding: 30px 0 20px;
}
.pay .pay_title[data-v-fde20090] {
  font-size: 22px;
  margin-bottom: 15px;
}
.pay .pay_title .iconfont[data-v-fde20090] {
  color: #15ba11;
  font-size: 14px;
}
.pay .pay_img[data-v-fde20090] {
  margin-top: 30px;
}
.pay .pay_img img[data-v-fde20090] {
  width: 230px;
  height: 230px;
}
.pay .ai_close[data-v-fde20090] {
  position: absolute;
  right: 15px;
  top: 15px;
  cursor: pointer;
}
.renew_top[data-v-fde20090] {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.renew_top .iconfont[data-v-fde20090] {
  color: var(--themeColor, #17a2b8);
  margin-right: 4px;
  font-size: 13px;
}
.renew_top .renew_amount[data-v-fde20090] {
  color: var(--themeColor, #17a2b8);
}